import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

export default class ContactUsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s | Pathlab">
          <title>{`Phlebotomy Open Day`}</title>
          <meta
            name="description"
            content={`Register your interest in our Phlebotomy Open Day.`}
          />
        </Helmet>
        <section className="section">
          <div className="container content-border">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <iframe
                    id="contact-us-form"
                    title="Contact Us Form"
                    src="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=AWoWqGyZBU2T4SubP7FHPuMFEfVfJNdMrPLc4msvdpFUQ1k1RTlYNFZVSjhIVkVDSjFFN0dTRkJCQi4u"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
